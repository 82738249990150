.parent {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(auto-fill, 1fr);
    grid-column-gap: 10px;
    grid-row-gap: 10px;
    justify-content: center;
}

.div1 { grid-area: 1 / 3 / 3 / 4; }
.div2 { grid-area: 1 / 1 / 3 / 3; }
.div3 { grid-area: 3 / 1 / 4 / 2; }
.div4 { grid-area: 3 / 2 / 4 / 3; }
.div5 { grid-area: 3 / 3 / 4 / 4; }
.div6 { grid-area: 4 / 1 / 5 / 2; }
.div7 { grid-area: 4 / 2 / 5 / 3; }
.div8 { grid-area: 4 / 3 / 5 / 4; }

@media screen and (min-width: 1300px){
    .parent{
        grid-row-gap: 20px;
        grid-column-gap: 20px;
    }
}