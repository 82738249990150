.ReactModal__Overlay {
    transition: transform 300ms ease-in-out;
    transition-delay: 100ms;
    transform: scale(0);
    /* transform: translateY(-150%); */
}

.ReactModal__Overlay--after-open{
    transform: scale(1);
    /* transform: translateY(0%); */
}

.ReactModal__Overlay--before-close{
    transform: scale(0);
    /* transform: translateY(-150%); */
}

input::-webkit-input-placeholder{
    font-size: 15px !important;
}



input {
    width: 100% !important;
    height: 2em !important;
    padding: 10px !important;
}


.css-gga68z-MuiInputBase-input {
    padding: 10px !important;
    height: 2em !important;

}

.hOKZZj{
    min-height: 0 !important;
}

#hint{
    width: 80% !important;
}

@media screen and (min-width: 280px) and (max-width: 580px){
   #hint{width:95% !important;}
    .two{margin-bottom: 120px !important;}
    .three{margin-bottom: 230px !important;}
    .four{margin-bottom: 330px !important;}
}

@media screen and (min-width: 580px) and (max-width: 900px){
    #hint{width:95% !important;}
    .two{margin-bottom: 80px !important;}
    .three{margin-bottom: 160px !important;}
    .four{margin-bottom: 240px !important;}
}

@media screen and (min-width: 900px) and (max-width: 1080px){
    .MuiAlert-filledInfo{width:850px !important;}
    .MuiAlert-filledWarning{width:1050px !important;}
    .one{margin-bottom: -20px;}
    .two{margin-bottom: 51px !important;}
    .three{margin-bottom: 122px !important;}
    .four{margin-bottom: 180px !important;}
    .content{margin-right: 260px !important;}
    .content-left{margin-left: 260px !important;}
}

@media screen and (min-width: 1080px){
    .MuiAlert-filledInfo{width:1050px !important;}
    .MuiAlert-filledWarning{width:1050px !important;}
    #hint{width:1000px !important;}
    .one{margin-bottom: -20px;}
    .two{margin-bottom: 60px !important;}
    .three{margin-bottom: 99px !important;}
    .four{margin-bottom: 157px !important;}
    .content{margin-right: 260px !important;}
    .content-left{margin-left: 260px !important;}
}

.scrollhost::-webkit-scrollbar {
    display: none !important;
}

.scrollhost ::-moz-scrollbar {
    display: none;

}
.scrollhost { overflow: -moz-scrollbars-none; }
.scrollhost::-webkit-scrollbar { width: 0 !important }
.scrollhost {
    overflow: auto;
    -ms-overflow-style: none;
    z-index: -1;
    scrollbar-color: transparent transparent; /*just hides the scrollbar for firefox */
}

.hints{
    display: flex !important;
}

.MuiListItem-gutters {
  justify-content: center !important;
}

.SnackbarItem-message-61, .SnackbarItem-message-42, .SnackbarItem-message-38, .SnackbarItem-message-79, .SnackbarItem-message-104, .SnackbarItem-message-202 {
    width: 85%;
}

.cafjaf{
    min-height: auto !important;
    padding: 10px;
}

.custom-calendar{
    min-width: 250px !important;
    max-width: 250px !important;
}

.MuiPickersToolbar-toolbar{
    height: 70px !important;
}

.MuiPickersToolbarText-toolbarBtnSelected {
    font-size: 1.7em !important;
    text-transform: uppercase;
    font-weight: bolder;
}

.MuiPaper-root{

}

.MuiDrawer-paper:hover{
    overflow-y: scroll !important;
}



/* [tip-text]:hover::after {
    content: attr(tip-text);;
    font-weight: bold;
    font-size: 0.7rem;
    color: rgba(247,250,252);
    background-color: rgba(100, 21, 255);
    padding: 5px 10px;
    position:relative;
    white-space: nowrap;
    border-radius: 5px;
} */

